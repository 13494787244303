<template>
  <v-container>
    <Order
        v-if="!api.loading"
        :order="order"
        @download="downloadBook"
    />
  </v-container>
</template>

<script>
import Http from '@/api/Http';
import Order from '@/components/Order.vue';

export default {
  name: 'AccountOrderDetails',
  components: {
    Order
  },
  data() {
    return {
      api: Http.use('data'),
      order: {
        billing: {name: '', address: {}},
        shipping: {name: '', address: {}},
        book: { coverUrl: '' },
        digitalBook: null,
        bookItem: {},
        pages: {}
      }
    }
  },
  created() {
    this.index();
  },
  computed: {
    orderDate() {
      let ret = '';
      if (this.order.created) {
        ret = (new Date(this.order.created)).toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
      }
      return ret;
    },
    statusDate() {
      let ret = '';
      if (this.order.statusDate) {
        ret = (new Date(this.order.statusDate)).toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
      }
      return ret;
    }
  },
  methods: {
    index() {
      this.api.get(`orders/${this.$route.params.id}`)
          .then(order => {
            this.order = order;
          })
          .then(() => {
            if (this.$route.params.action === 'digital' && this.order.digitalStatus === 'ready') {
              this.downloadBook();
            }
          });
    },
    downloadBook() {
      this.api.get(`orders/${this.$route.params.id}/digital`, null, {responseType: 'blob'})
          .then((response) => {
            const url = window.URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'JamBook-digital.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
          })
          .catch(err => {
            console.log(err);
          });
    }
  }
}
</script>