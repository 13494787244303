<template>
  <div>
    <v-card outlined class="pb-5">
      <v-card-text>
        <span style="font-size: 1.5em;">Order # {{ order.orderId }}</span>
      </v-card-text>
      <v-container>
      <v-row>
        <v-col cols="12" md="7" align-self="start">
          <v-row>
            <v-col>
              {{ order.jamName }}<br/>
              <v-img :src="order.book.coverUrl" width="300" height="267" /><br/>
            </v-col>
            <v-col>
              Status: {{ order.status }}
            </v-col>
          </v-row>
          <v-btn block v-if="order.digitalBook && order.digitalStatus === 'ready'" @click="$emit('download')">Download digital book</v-btn>
        </v-col>
        <v-col cols="12" md="5">
          <v-row>
            <v-col cols="8">
              {{ order.bookItem.name }} ${{ formatMoney(order.bookItem.unitAmount) }} x {{ order.bookItem.quantity }}<br/>
              <span v-if="order.hardCover">{{ order.hardCover.name }}<br/></span>
              <span v-if="order.pages">{{ order.pages.name }} ${{ formatMoney(order.pages.unitAmount) }} x {{ order.pages.quantity}}<br/></span>
              <span v-if="order.digitalBook">{{ order.digitalBook.name }}</span>
            </v-col>
            <v-col cols="4" style="text-align: right;" class="pr-8">
              ${{ formatMoney(order.bookItem.unitAmount * order.bookItem.quantity) }}<br/>
              <span v-if="order.hardCover">${{ formatMoney(order.hardCover.unitAmount) }}<br/></span>
              <span v-if="order.pages">${{ formatMoney(order.pages.unitAmount * order.pages.quantity) }}<br/></span>
              <span v-if="order.digitalBook">${{ formatMoney(order.digitalBook.unitAmount ) }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-divider />
          </v-row>
          <v-row>
            <v-col cols="8">
              Item(s) Subtotal:<br/>
              Shipping & Handling:<br />
              Total before tax:<br />
              Tax:<br />
              Discounts: <br/>
            </v-col>
            <v-col cols="4" style="text-align: right;" class="pr-8">
              ${{ formatMoney(order.amountSubtotal) }}<br/>
              ${{ formatMoney(order.amountShipping) }}<br/>
              ${{ amountBeforeTax }}<br/>
              ${{ formatMoney(order.amountTax) }}<br/>
              ${{ formatMoney(order.amountDiscount) }}<br/>
            </v-col>
          </v-row>
          <v-row>
            <v-divider />
          </v-row>
          <v-row>
            <v-col><strong>Total (USD):</strong></v-col>
            <v-col cols="4" style="text-align: right;" class="pr-8">
              $ {{ formatMoney(order.amountTotal) }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      </v-container>
    </v-card>
    <v-card outlined class="pt-1 mt-5 pb-5">
      <v-card-text>
        <span style="font-size: 1.5em;">Order details</span>
      </v-card-text>
      <v-row>
        <v-col class="pl-8">
          <strong>Contact information</strong><br/>
          {{ order.email }} <br/>

          <br/><strong>Shipping address</strong><br/>
          {{ order.shipping.name }}<br/>
          {{ order.shipping.address.line1}}<br/>
          <span v-if="order.shipping.address.line2">{{ order.shipping.address.line2 }}</span>
          {{ order.shipping.address.city }} {{ order.shipping.address.state }} {{ order.shipping.address.postal_code }}<br/>

          <br/><strong>Shippping method</strong><br/>
          {{ order.shippingMethod }}<br/>
        </v-col>
        <v-col>
          <strong>Payment method</strong><br/>
          {{ order.paymentMethod }}<br/>
          <br/><strong>Billing address</strong><br/>
          {{ order.billing.name }}<br/>
          {{ order.billing.address.line1}}<br/>
          <span v-if="order.billing.address.line2">{{ order.billing.address.line2 }}</span>
          {{ order.billing.address.city }} {{ order.billing.address.state }} {{ order.billing.address.postal_code }}<br/>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'Order',
  props: {
    order: {
      type: Object
    }
  },
  data() {
    return {}
  },
  computed: {
    amountBeforeTax() {
      return this.formatMoney(this.order.amountSubtotal + this.order.amountShipping);
    }
  },
  methods: {
    formatMoney(a) {
      return (a/100).toFixed(2);
    }
  }
}
</script>